import { default as WebauthnController } from "topsail_webauthn/app/javascript/controllers/webauthn_controller"

export default class extends WebauthnController {
  static targets = [ // in addition to the WebauthnController.targets
    "authMethodSelector", // the dropdown selecting the 2nd factor
    "passwordField",
    "errors" // for displaying error messages
  ]

  signInViaPasskey(clickEvt){
    super.signIn(clickEvt).
      catch(error => { this._showError(error) })
  }

  authMethodChanged(_changeEvt){
    this._showHideControls()
  }

  _showHideControls(){
    this.passwordFieldTarget.style.display = (this._mfa() ? "none" : "")
  }

  _mfa(){
    return this.authMethodSelectorTarget.value == 'u2f'
  }

  _showError(error){
    this.errorsTarget.innerText = error.toString()
  }
}
