import { Controller } from "stimulus"
import sha256 from "crypto-js/sha256" // use crypto-js 3.3, becuase 4.0 pulls in way too big bundle (1M vs. couple of K)
import enc_latin1 from "crypto-js/enc-latin1"

export default class extends Controller {
  static targets = ["imageCode", "imageFileName"]
  static values = {
    imageSalt:      String,
    imageChallenge: String,
  }

  generateImageResponse(evt){
    let reader = new FileReader();

    reader.addEventListener(
      "loadend",
      () => {
        let fileHash = sha256(enc_latin1.parse(reader.result)),
            salted = sha256(this.imageSaltValue + fileHash),
            challanged = sha256(salted + this.imageChallengeValue)
        this.imageCodeTarget.value = challanged
        this.imageFileNameTarget.innerText = evt.target.files[0].name
        document.querySelector("input[type=submit]").disabled = false
      })
    reader.readAsBinaryString(evt.target.files[0]);
  }
}
