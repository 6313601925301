/*
  Catches click of all links nested inside, and updates the ts-loadable.extra-params
  of the closest ts-loadable ancestor with data specified by the @param-name attribute.
  Afterwards forces load() on ts-loadable.

  param-name - the url parameter name to extract from the nested links and used to update the ts-laodable extra-params
*/

import { serialize_as_object } from "../util"

class TsLoadableParamUpdater extends HTMLElement {

  connectedCallback(){
    this.param_name = this.getAttribute("param-name");
    this.addEventListener("click", this.update_param.bind(this));
  }

  update_param(event){
    if (typeof event.target.href != 'undefined') {
      var clicked_href_query = event.target.href.split("?")[1],
          param_val = serialize_as_object(clicked_href_query)[this.param_name],
          loadable = this.closest("ts-loadable");
      loadable.update_extra_params(this.param_name, param_val)
      loadable.load();
    }
    event.preventDefault();
  }
};

customElements.define('ts-loadable-param-updater', TsLoadableParamUpdater);