export default `
  ts-loadable-sorter {
    cursor: pointer;
    white-space: nowrap;
  }

  ts-loadable-sorter:after {
      font-size: 0.6em;
      vertical-align: top;
      padding-left: 0.5em;
      opacity: 0.2;
  }
  ts-loadable-sorter:after { content: '▲' attr(sort-pos); }
  ts-loadable-sorter[sort-pos="1"]:after { content: '▲'; }
  ts-loadable-sorter[sort-pos][desc]:after{ content: '▼' attr(sort-pos); }
  ts-loadable-sorter[sort-pos="1"][desc]:after{ content: '▼'; }

  ts-loadable-sorter[sort-pos]:after { opacity: 1;} /* sorted */

  /* we are not using this anymore */
  ts-loadable-sorter span.sort_position { display: none; }

  ts-loadable-sorter[sort-pos]:not([desc]) > span.sort_position {
    margin-top: 1em;
  }
`