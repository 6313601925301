/*
  This uses JQuery!

  attributes:   href
                insert (optional) - "top" or "bottom", inserts resulting content rather than
                                    replace the entire element content
                extra-params (optional) - JSON of initial extra_params
                lazyload (optional)     - don't immediately load the content
                extra-params-form-selector (optional) - a selector string identifying a form element, that will be used as
                                                        property 'extra_params_form' (see below)

  properties:  extra_params - a plain object passed in to subsequent AJAX load requests;
                              when setting, you can provide a plain object or a "railsy" request string (the setter converts the string to an object)
               extra_params_form - a form dom element; on submit of the form, the extra_params are being replaced with the form's
                                   current input values, and the ts-loadable will be reloaded

  methods: load() - forces the element to fetch new content via AJAX, using it's @href and #extra_params
           update_extra_params(param_name, value) - sets the value of existing extra_params'
                                                    key identified by param_name (key
                                                    can be complex like "filter[id][]")

  events: ts-loaded - fired once the content is updated after a load()

  interactivity cues: ts-loadable.loading - class appended during load(); cleared when content is updated

*/

import { fireEvent, serialize_as_string, serialize_as_object, railsy_update_property_hash } from "../util.js"
import { default as Limiter } from "async-limiter"
import { default as $ } from "jquery"
import { default as css_template } from "./css_template.js"

const css = document.createElement('style');
css.append(css_template);
document.head.append(css);

/* Limit number of concurrent requests.
   Browsers used to limit concurrent requests to the same server to 4.
   Since HTTP/2 this limit is gone.
   Huge numbers of concurrent reuests can lead to resource starvation on the server, since the server will try to process
   all incoming requests (even if they will be aborted), filling up server side request queues.
   So we prevent too many requests getting to the server concurrently in the first place clinet side. */
const QUEUE_LIMIT = 5

class TsLoadable extends HTMLElement {

  connectedCallback(){
    // initialize global concurrency throttling queue
    if (!TsLoadable.limiter){
      TsLoadable.limiter = new Limiter({ concurrency: QUEUE_LIMIT })
    }

    // connectedCallback can run multiple times.
    // Since we must avoid double-initializetion (and running #load() too often), keeping track of
    // initialization here too
    if (!this._initialized){
      this._initialized = true

      // set initial extra_params
      this.extra_params = this.hasAttribute("extra-params") ?
                              JSON.parse(this.getAttribute("extra-params")) :
                              {};
      if (this.hasAttribute("extra-params-form-selector")){
        var f = document.querySelector(this.getAttribute("extra-params-form-selector").replace(/\//g,"\\/"));
        if (f){
          this.extra_params_form = f;
        }
      }

      // load on startup unless told otherwise
      if (!this.hasAttribute("lazyload")){ this.load(); }
    }
  }

  get extra_params(){
    return this._extra_params;
  }

  set extra_params(string_or_object){ // url_request params or hash/object
    this._extra_params = typeof string_or_object == "string" ?
                                    serialize_as_object(string_or_object) :
                                    string_or_object;
  }

  update_extra_params(param_name, value){
    if (!(typeof this._extra_params == "object")) {
      this._extra_params = {};
    }
    railsy_update_property_hash(this._extra_params, param_name, value);
  }

  get extra_params_form(){
    return this._extra_params_form;
  }

  set extra_params_form(form_el){
    this._extra_params_form = form_el;
    this.extra_params = serialize_as_string(form_el); // in case the form has some pre-sets
    form_el.addEventListener("submit",function(){
        this.extra_params = serialize_as_string(form_el);
        this.load();
    }.bind(this));

    // The ts-loadable fetches it's own content.
    // So we need to prevent this UJS form from submitting; Rails 5.0 has a bug where you can't easily cancel the request.
    // Dropping down to jQuery to prevent the double submit.
    if (form_el.hasAttribute("data-remote")) {
      $(form_el).on("submit", function(){ return false } )
    }
  }

  load(force){
    // allow listeners to prevent fetching
    if ( !force && !fireEvent(this, "ts-load") ){ return false; }

    this.classList.add("loading");
    this._current_jqxhr && this._current_jqxhr.abort(); // kill possible pending request

    // To avoid race condition, store request prams before enqueueing in an async queue.
    // this.el might change between enqueueing and execution.
    const href = this.getAttribute('href'),
          extraParams = this.extra_params,
          insertAt = this.getAttribute("insert");
    TsLoadable.limiter.push((done)=>{
      this._current_jqxhr = fetchRemoteData(this, href, extraParams, insertAt, done)
    })
  }
};

/* Fetch new content.
   This method is called asynchronously ("async-limiter").
   This also means that `el` might have changed between enqueueing and execution of this method.
   So we pass in all interesting properties of `el` */
function fetchRemoteData(el, href, extraParams, insertAt, queueDoneCallback){
  return $.get(
    href,
    extraParams,
    function(responseText, _textStatus, jqXHR){
      el._current_jqxhr = null;

      if (insertAt == "top") {
        $(el).prepend(responseText);
      } else if (insertAt == "bottom") {
        $(el).append(responseText);
      } else {
        $(el).html(responseText); // use jquery instead of innerHTML so that JS gets executed
      }

      // After updating the element's content, store some additional data
      let finder = /^X-TS-(.*?):[ \t]*([^\r\n]*)\r?$/mgi, // IE leaves an \r character at EOL
          ts_response_headers = {},
          match;
      while ( (match = finder.exec( jqXHR.getAllResponseHeaders() )) ) {
        ts_response_headers[ match[1].toLowerCase() ] = match[ 2 ];
      }
      el.ts_response_headers = ts_response_headers;
      el.last_response_time = jqXHR.getResponseHeader("X-Runtime")

      fireEvent(el, "ts-loaded")
      el.classList.remove("loading")
    }).always(()=>{ queueDoneCallback() });
}

customElements.define('ts-loadable', TsLoadable);
window.TsLoadable = TsLoadable