/*
  name - the sort parameter to be used in the ts-loadable when sorting by this sorter
  sort-pos (optional) - if present, this column is considered sorted. The value is the 1-based sort index
                            (e.g. secondary sort has "2", tertiary = "3" etc.)
  desc (optional) - if present, and sort-pos is present, this column is reverse sorted
*/
import { default as css_template } from "./sorter-css_template"

const css = document.createElement('style');
css.append(css_template);
document.head.append(css);

class TsLoadableSorter extends HTMLElement {
  connectedCallback(){
    this.addEventListener("click", this.toggle_sort.bind(this));
    this.addEventListener("contextmenu", this.toggle_sort.bind(this));
  }

  toggle_sort(event){
    var is_secondary_sort = (event.type == "contextmenu"),
        sort_val = this.getAttribute("name");

    event.preventDefault();

    if (this.hasAttribute("sort-pos") && !this.hasAttribute("desc")) sort_val = sort_val + " desc";

    var l = this.closest('ts-loadable');
    if (is_secondary_sort){
      // wipe out trailing secondary sorts
      (l.extra_params.sort || []).splice((this.getAttribute("sort-pos") || 1000) - 1);
      // append the new sort
      l.update_extra_params('sort[]', sort_val);
    } else {
      l.update_extra_params('sort[]', [sort_val]);
    }
    l.load();
  }
};

customElements.define('ts-loadable-sorter', TsLoadableSorter);
